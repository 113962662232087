import * as TYPES from './PanelDeliveryPackagingIssues.types'

export const NON_REPORTABLE_PATH = '/customer-service'

export const DEFAULT_MODAL_OPTIONS: Array<TYPES.DeliveryPackagingIssueOption> = [
  {
    text: 'The shipment was missing a few items',
    urlDestination: '/report-issue-select-items-base',
    issueType: 'missing_items',
  },
  {
    text: 'The meat arrived thawed',
    urlDestination: '/report-issue-select-items-select-all',
    issueType: 'thawed',
  },
  {
    text: 'The plastic packaging was torn/broken/split',
    urlDestination: '/report-issue-select-items-select-all',
    issueType: 'broken_packaging',
  },
  {
    text: "My box didn't arrive",
    urlDestination: NON_REPORTABLE_PATH,
    issueType: 'no_arrival',
  },
  {
    text: 'Other',
    urlDestination: '/other',
    issueType: 'other',
  },
]
