import React from 'react'
import useOrders from '~/hooks/useOrders'
import { LoadingPanel } from '../../LoadingPanel/LoadingPanel'
import useModalOptions from './hooks/useModalOptions'
import { DEFAULT_MODAL_OPTIONS } from './PanelDeliveryPackagingIssues.constants'
import PanelDeliveryPackagingIssuesUI from './PanelDeliveryPackagingIssues.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelDeliveryPackagingIssues: React.FC<CancelPanelProps> = ({
  panel,
}) => {
  // retrieve current order to determine if reportable
  const { data: [order] = [] } = useOrders()
  const modalOptions = useModalOptions(DEFAULT_MODAL_OPTIONS, order)

  return order ? (
    <PanelDeliveryPackagingIssuesUI modalOptions={modalOptions} panel={panel} />
  ) : (
    <LoadingPanel />
  )
}

export default PanelDeliveryPackagingIssues
