import { useNavigate } from '@reach/router'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import {
  DEFAULT_MODAL_OPTIONS,
  NON_REPORTABLE_PATH,
} from '../PanelDeliveryPackagingIssues.constants'
import type * as TYPES from '../PanelDeliveryPackagingIssues.types'

const useModalOptions: TYPES.useModalOptionsHook = (
  options = DEFAULT_MODAL_OPTIONS,
  order
) => {
  const { orderIssue, updateOrderIssue } = React.useContext(
    ReportAnIssueContext
  )
  const navigate = useNavigate()
  return options.map((option) => {
    return {
      text: option.text,
      // if an order is reportable
      // we update the order issue before directing
      // the user to the provided destination
      // if the order isn't reportable, we don't update
      // the order issue, but pass them through to the
      // custom-service path
      clickHandler: order.reportable
        ? function () {
            updateOrderIssue({ ...orderIssue, issueType: option.issueType })
            navigate(option.urlDestination, { state: { text: option.text } })
          }
        : function () {
            navigate(NON_REPORTABLE_PATH, { state: { text: option.text } })
          },
      cypressId: TEST_ID.CANCEL_FLOW_OPTION,
    }
  })
}

export default useModalOptions
