import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import type * as TYPES from './PanelDeliveryPackagingIssues.types'

const PanelDeliveryPackagingIssuesUI: React.FC<TYPES.PanelDeliveryPackagingIssuesUIProps> = ({
  panel,
  modalOptions,
}) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body mb={rem(16)}>{panel.subhead}</Body>
      <Grid gap={rem(16)} gridTemplateColumns="1fr">
        {modalOptions.map(({ text, clickHandler, cypressId }) => (
          <ModalItemSmall
            data-attr-target-intent="Operations - Packaging"
            data-attr-target-save-component="Navigation"
            data-cy={cypressId}
            key={text}
            onClick={() => clickHandler()}
          >
            {text}
          </ModalItemSmall>
        ))}
      </Grid>
    </PanelLayout>
  )
}

export default PanelDeliveryPackagingIssuesUI
